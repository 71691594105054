.drag-handle {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.drag-handle-active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.drag-handle:active {
  .drag-handle-active;
}

.ui.button.drag-handle {
  .drag-handle;
}
.ui.button.drag-handle:active {
  .drag-handle-active;
}

.gu-mirror .ui.button.drag-handle {
  .drag-handle-active;
}
