#app-tasklist {
  position: relative;

  .ui.sidebar.tasklist {
    overflow-y: visible !important;
    visibility: visible;
    padding: 50px 20px 20px;
    width: @taskBarWidth;

    table  tr.empty > td {
      text-align: center;
    }
  }

  .task-list-tab-button {
    height: 4em;
    padding-top: 1.1em;
    padding-right: 3em;
    transform: rotate(-90deg);
    position: absolute;
    left: -6.3em;
    bottom: 5em;
    background: @white;
    color: @primaryColor;
    border-radius: 0;
    border: 1px solid @taskBarToggleButtonBorderColor;
    border-bottom: none;

    &.muted {
      padding-right: 1.5em;
      left: -5.6em;
    }

    .button-badge {
      transform: rotate(90deg);
      position: absolute;
      top: 1em;
      right: 0.75em;

      .badge-gray {
        color: @taskBarBadgeDefaultColor;
      }
    }
  }

  .task-list-close-button {
    position: absolute;
    right: 20px;
    top: 15px;
    font-size: 1.3em;
    color: @taskBarCloseButtonColor;
  }
}
