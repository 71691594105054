.ui.form .fields.one {
    margin-bottom: 1em;
}

.ui.form .fields.one:last-child {
    margin-bottom: 0;
}

.designview-table-columns {
  .ui.mini.button {
    font-size: .71428571rem;
  }

  .ui.input {
    font-size: inherit;
  }

  .ui.dropdown {
    min-width: auto;

    .menu {
      font-size: inherit;

      > .item {
        font-size: inherit;
      }
    }
  }
}
