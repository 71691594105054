.ui.grid.dashboard > .row > .column:last-child > .horizontal.segment {
  box-shadow: 0px 0px 0px 1px @transparentBlack, 0px 1px 2px 0 @transparentBlack;
}

.ui.segment.dashboard:not(.attached) {
  padding: 2em;

  .ui.header {
    margin: 0;
    text-align: right;

    >.icon {
      font-size: 2.5em;
      padding: 0;

      +.content {
        width: 100%;
      }
    }

    .content {
      color: @primaryColor;
      font-size: 2em;

      .sub.header {
        color: @lightTextColor;
        font-size: 15px;
        padding-top: 0.5em;
      }
    }
  }
}

.ui.segment.attached.dashboard {
  box-shadow: none;
}

.dashboard.table.container {
  background: @white;
  border: 1px solid #D0D0D0;
  border-radius: 0em 0em 0.25rem 0.25rem;
  box-shadow: 0px 1px 2px 0 @transparentBlack;
  margin: -1px;
  min-height: 216px;

  .ui.table {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    table-layout: fixed;
    width: 100%;

    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    thead tr:first-child {

      > th {
        &:first-child,
        &:last-child {
          border-radius: 0;
        }
      }
    }
  }
}
