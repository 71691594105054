.ui.button.minimal.icon {
  display: inline;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  color: @black;

  &:hover {
    background-color: transparent;
    text-shadow: none;
    border: none;
  }

  &.primary {
    color: @primaryColor;
  }

  &.red {
    color: @red;
  }
}
