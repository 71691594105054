//TODO: We're a rem/em based framework. Re-do the pixels
.campaign-period-row {
  width: 700px;
  margin-left: 25px;
  margin-bottom: 25px;
  display: table-row;

  .field:not(:first-child) {
    margin-left: 25px;
    display: table-cell;
    width: 22em;
  }

  .field {
    display: table-cell;
    width: 22em;
    padding-right: 1em;
  }
}

.campaign-period-row.reminder-dropdown .input-box {
  width: 26em;
  margin-bottom: 1.5em !important;
  display: block;
}

.input-box {
  display: inline-block;
  width: 100%;

  .dropdown.selection {
    width: 100%;
  }
}

.example-email-link {
  margin-top: 25px;
}
