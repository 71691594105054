
// wrap in & {} so that variables do not effect other files
& {
  @type    : 'collection';
  @element : 'menu';
  @import (multiple) '@{themeConfigFile}';

  @verticalPadding: 1.25em;
  @horizontalPadding: 1em;
  @iconMargin: 0em 0.5em 0em 0em;
  @iconFloat: left;

  #app-sidebar > .ui.menu > .item.image {
    padding-top: 2em;
    padding-bottom: 2em;
  }

  #app-sidebar > .ui.menu > .item.image img {
    margin: auto;
  }

  #app-sidebar > .ui.menu > .item:before {
    background: none !important;
  }
  #app-sidebar > .ui.menu > .item:after {
    position: absolute;
    content: '';
    bottom: 0%;
    left: 0px;
    width: 100%;
    background: @dividerBackground;
    height: @dividerSize;
  }

  #app-sidebar > .ui.menu > .item {
    padding: @verticalPadding @horizontalPadding;
  }

  #app-sidebar > .ui.menu .item:not(.dropdown) > i.icon {
    float: @iconFloat;
    margin: @iconMargin;
  }

  #app-sidebar > .ui.menu .tree.item .node > .title > i.icon:not(.dropdown) {
    opacity: @iconOpacity;
  }

  #app-sidebar > .ui.menu .tree.item {
    padding: 0;
  }

  #app-sidebar > .ui.menu .tree.item > .node > .title {
    position: relative;
    padding: @verticalPadding @horizontalPadding;
  }

  #app-sidebar > .ui.menu .tree.item > .node > .content {
    background: @hoverBackground;
    padding: @verticalPadding @horizontalPadding;
    padding-left: 2em;
  }

  #app-sidebar > .ui.menu .tree.item > .node.selected > .title {
    color: @primaryColor;
  }

  #app-sidebar > .ui.menu .tree.item > .node.selected > .title:after {
    position: absolute;
    content: '';
    bottom: 0%;
    left: 0px;
    width: 100%;
    background: @dividerBackground;
    height: @dividerSize;
  }

  #app-sidebar > .ui.menu .tree.item > .node:not(.selected) > .title:hover {
    background: @hoverBackground;
  }

  #app-sidebar > .ui.menu .tree.item > .node .node > .title {
    opacity: 0.8;
  }

  #app-sidebar > .ui.menu .tree.item > .node .node > .title:hover {
    opacity: 1;
  }
}
