//forces the table to stack rows instead of displaying horizontally like a standard table
.significant-financial-interest-relationships-table-view td {
  display: block !important;
}

//correct line spacing issues as consequence of stacking rows
.significant-financial-interest-relationships-table-view td:nth-child(2) {
  margin-top: -1em;
}
.significant-financial-interest-relationships-table-view .delete-relationship {
  margin-bottom: -1em;
}

//add a divider to separate relationships
.ui.significant-financial-interest-relationships-table-view .top-border-stacked-cell {
  border-top: 1em solid @lightGrey;
}

.ui.significant-financial-interest-relationships-table-view .no-border-stacked-cell {
  border-top: none;
}

.significant-financial-interest-relationships-table-view .accept-relationship {
  margin-top: -0.4em;
}

.significant-financial-interest-relationships-table-view {

  .ui.blue.button {
    border: 1px solid @primaryColor;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .ui.secondary.buttons .button,
  .ui.secondary.button {
    background-color: #ffffff;
    color: #0076b6;
    text-shadow: none;
    background-image: none;
    border: 1px solid #0076B6;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .ui.secondary.button {
    box-shadow: 0px 0em 0px 0px rgba(39, 41, 43, 0.15) inset;
  }
  .ui.secondary.buttons .button:hover,
  .ui.secondary.button:hover {
    background-color: #DDF3FF;
    color: #0076b6;
    text-shadow: none;
    border: 1px solid #0076B6;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .ui.secondary.buttons .button:active,
  .ui.secondary.button:active {
    background-color: #DDF3FF;
    color: #0076b6;
    text-shadow: none;
    box-shadow: 0px 0px 5px #0076B6;
    border-radius: 4px;
  }
  .ui.secondary.buttons .active.button,
  .ui.secondary.active.button {
    background-color: #DDF3FF;
    color: #0076b6;
    text-shadow: none;
  }
}