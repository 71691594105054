.ui.counter {
  display: inline-block;
  position: relative;
}

.ui.counter input[type=number] {
  padding-right: 3em;
}

.ui.counter .ui.vertical.buttons {
  margin-right: 1px;
  position: absolute;
  right: 0px;
  top: 1px;
}
.ui.counter .ui.vertical.buttons .increment.button {
  padding-top: 0.25em;
  padding-bottom: 0.1em;
}
.ui.counter .ui.vertical.buttons .decrement.button {
  padding-bottom: 0.25em;
  padding-top: 0em;
}

.ui.counter input[type=number] {
  -moz-appearance: textfield;
}
.ui.counter input::-webkit-outer-spin-button,
.ui.counter input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.ui.fluid.counter {
  display: block;
}