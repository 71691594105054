/*
this file was created to overwrite CSS for header of app/view/disclosure/disclosure.send.adhoc.view.js ,
some additional classes for this page were added later.
 */

.simplyTransparent {
  background-color: transparent;
  padding-left: 0em;
}

.extraMargin {
  margin-bottom: 2em;
}

.adHocActions .actions {
  text-align: right;
  padding-top: 2em;
  margin-top: 1.5em;
  margin-bottom: 2.5em;
}

.adHocActions .ui.table thead {
  display: none !important;
}

.adHocActions .ui.attached.table td {
  padding-left: 4em;
  padding-right: 4em;
  border-top: none;
}

.adHocActions .ui.attached.table {
  border: none;
  box-shadow: none;
}

.adHocActions .actions .ui.message.positive {
  background-color: #eeffe7;
  color: #3c763d;
  text-align: left;
  padding-bottom: 1em;
}

.adHocActions .actions .ui.message.positive .text {
  margin-top: 0.1em;
  padding-top: 0.1em;
  padding-bottom: 0.5em;
  font-size: 16px;
}

.adHocActions .empty {
  display: none;
}

.adHocActions .tab .text {
  color: #333333;
  margin-top: 3em;
  margin-bottom: 2.5em;
  padding-top: 2em;
}

.adHocActions .tab .ui.attached.header {
  border: none;
  padding-left: 0em;
  margin-top: 1em;
}

.adHocActions .type-ahead-find {
  width: 100%;
}

.adHocActions .firstName {
  display: inline-inline-block;
}

.unitColumn {
  text-align: right !important;
  padding-right: 1em !important;
  width: '65%';
  // white-space: pre-wrap;
  // word-wrap: break-word;
}

.adHocActions .ui.button.minimal.icon.red {
  font-size: 1.5em;
}

.adHocClose {
  width: 3%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.adHocteamselectedmembers {
  padding-top: 1em;
}
