#app-header {
  height: 122px;
}

#app-header.no-nav {
  height: 71.6px;
}

.dropdown-text {
  font-weight: 700;
}

.shared-header-nav {
  display: flex;
  align-items: stretch;
  padding-left: 0;
  top: 71.6px!important;
  margin: 0!important;
  z-index: 100!important;
  height: 50px;

  &:after {
    content: none!important;
  }
  .item:before {
    content: none!important;
  }

  &.ui.menu .item {
    padding: 10px 30px !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 0 !important;
    color: #333;
  }

  .header-dropdown {
    padding: 10px 30px !important;
  }

  .ui.dropdown .menu {
    box-shadow:  2px 4px  rgba(51, 51, 51, 0.5) !important;
    > .header {
      margin: 0 !important;
      text-transform: none!important;
      padding: 10px 10px 10px 20px !important;
      font-size: 13px !important;
      font-weight: 700;
      color: #333333 !important;
    }

    > .item {
      padding: 10px 10px 10px 40px !important;
      font-size: 13px !important;
      font-weight: normal;
      color: #333333 !important;
    }
  }

  .item.active, .header-dropdown.active-header {
      box-shadow: none !important;
      border-bottom: 4px solid #0076b6 !important;
      color: #0076b6 !important;
      background: #fff!important;

      &:hover {
        background: rgba(0,0,0,.03)!important;
      }
  }

  .item.header-dropdown.active, .header-dropdown .item.active {
    box-shadow: none !important;
    border-bottom: none!important;
    color: #333!important;
    background: #fff!important;

    &:hover {
      background: rgba(0,0,0,.03)!important;
    }

    &.active-header {
      border-bottom: 4px solid #0076b6 !important;
      color: #0076b6 !important;
    }
  }

  .active span.text {
      color: #0076b6 !important;
  }

  .menu.transition.visible {
      top: 54px;
  }

  &.ui.fixed.menu, &.ui.top.fixed.menu {
      border-bottom-width: 1px !important;
      border-bottom-color: #4a4a4a !important;
      border-bottom-style: solid !important;
      border-top-width: 1px !important;
      border-top-color: #4a4a4a !important;
      border-top-style: solid !important;
  }
}

.shared-header {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  height: 71.6px !important;

  .menu.right {
    padding-right: 15px;
    justify-content: flex-end;
    width: 100%;
   }
  .item, .menu {
    display: flex;
    align-items: stretch;
  }
 .item {
    padding: 4px!important;
    color: #333333 !important;
  }
  .item:before {
    content: none!important;
  }
  .item .icon {
    height: 16px;
  }
  .header-logo img, .header-logo svg {
    height: 42px;
  }

  .text {
    color: #333333 !important;
  }

  .header-dropdown {
    display: flex!important;
    align-items: center;

    .text i {
      width: 11px!important;
      height: 11px!important;
      margin: 0!important;
    }
    svg {
      width: 22px;
      height: 22px;
    }
    .text {
      display: inline-flex;
      align-items: center;
      padding: 0 15px;

      .dropdown-text {
        padding: 0 8px;
      }
    }
    .menu {
      box-shadow:  2px 4px  rgba(51, 51, 51, 0.5) !important;
      margin-top: 3px!important;
      padding: 10px 20px;

      > .item {
         padding: 10px 0 !important;
         line-height: 20px;

        i {
          width: 24px;
          color: #0076B6;
          opacity: 1;
        }

        &:hover {
          cursor: default;
          background: inherit;
          color: inherit;
        }

        &.link:hover {
          cursor: pointer;
          background: rgba(0,0,0,.03);
          color: rgba(0,0,0,.85);
        }
      }

      > .divider {
        border-top: 2px solid #333;
        margin: 10px 0;
      }
    }
  }
  &:after {
    content: none!important;
  }
}
