.ui.formObject:not(:last-child) {
  margin-bottom: 2em;
}

.ui.formObject:first-child,
.ui.formObject:first-child > .ui.segment {
  margin-top: 0em;
}

.ui.sectionHeader,
.ui.sectionHeader .ui.segments > .ui.segment:first-child {
  margin-top: 0em !important;
}

.ui.formObject:first-child,
.ui.formObject:first-child > .ui.segment {
  margin-top: 0em;
}

.ui.sectionHeader,
.ui.sectionHeader .ui.segments > .ui.segment:first-child {
  margin-top: 0em !important;
}

.ui.form-section,
.ui.form-section .ui.segments > .ui.segment:first-child {
  margin-top: 0em !important;
}

.ui.formObject:first-child,
.ui.formObject:first-child > .ui.segment {
  margin-top: 0em;
}

.ui.sectionHeader,
.ui.sectionHeader .ui.segments > .ui.segment:first-child {
  margin-top: 0em !important;
}

.ui.form-section,
.ui.form-section .ui.segments > .ui.segment:first-child {
  margin-top: 0em !important;
}

.ui.form-section {
  cursor: pointer;
}

.ui.formObject .ui.segment.selectable {
  cursor: pointer;
}

.ui.segment.top.joined:first-child {
  margin-top: 0;
  border-top: 0;
  border-radius: 0;
}

.ui.segment.no-border-radius,
.ui.segment.no-border-radius:first-child {
  border-radius: 0;
}

//FormObjectCollectionView CSS for "preview"
.ui.formObjects .empty-view {
  //hide design view specific items.
  & .mainText,
  & .muted {
    display: none;
  }
  //apply styles to the section loading indicator
  .section-loading {
    background: none;
    font-size: 5em;
    width: 100%;
    margin: 0;

    &::before {
      border: 0.05em solid rgba(0, 0, 0, 0.08);
    }

    &::after {
      border-width: 0.05em;
      border-color: #000 transparent transparent;
    }

    .loading-text {
      display: block;
      font-size: 0.25em;
      position: relative;
      top: 3.5em;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

//FormObjectCollectionView CSS for "design view"
.form-design .ui.formObjects.empty {
  padding: 5em;
  border: 0.2em dashed gray;
  text-align: center;
  .empty-view {
    display: block;
  }
  .mainText {
    display: block;
    font-weight: bold;
  }
  .muted {
    display: block;
  }
  //turn off the section loading indicator in the FormObjectCollectionView's emptyView
  .section-loading {
    display: none;
  }
}

.ui.formObject .content .previewLabel,
.ui.formObject .content .previewText {
  font-weight: bold;
}

.ui.formObject .content .answerContainer {
  padding: 1.5em;
}

.ui.formObject .segment.top.toolbar {
  background: #eeeeee;
}

.ui.design.formObject .segment.top.toolbar.selectable:hover {
  background: #f5f5f5;
}

// Form Sections
.form-sections .header {
  line-height: 2.25em;
}

.form-section .controls {
  float: right;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.form-section .info {
  margin: 0 1rem;
  line-height: 2.25em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ui.button.section.failed.validations {
  float: right;
  margin: 0;
  min-width: 2em;
  padding: 0.5em;
  pointer-events: none;
}

// Form Object Type Menu
.form-object-type-menu .ui.segment {
  text-align: center;
  padding: 0.7em;
}

.form-object-type-menu:not(:first-child) {
  margin-top: 2em;
}

.form-object-type-menu .ui.segment:not(:last-child) {
  border-bottom: none;
}

// Errors
.ui.formObject:not(.error) > .ui.message.error {
  display: none;
}

// Help Text
.ui.popup > div.wrap-text {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
