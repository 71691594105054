.ui.data-label-selector {
  position: relative;
  padding-left: 3em;

  // menu toggle button
  .ui.button {
    position: absolute;
    left: 0;
    top: 3px;

    &.active {
      background-color: lighten(#e0e0e0, 5%);
      color: rgba(0,0,0, 0.4);
      box-shadow: inset 1px 1px 2px 1px rgba(167, 167, 167, 0.4) !important;
    }
  }

  // label text
  .ui.labelText {
    height: 2em;
    line-height: 2.5em;
  }

  // label menu
  .data-label-menu {
    position: absolute;
    max-height: 25em;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 5;
    top: 2.3em;
    left: 0;
    background: @white;
    margin-top: 0.5em;
    border-radius: 0.25em;
    box-shadow: 0 1px 3px 1px rgba(0,0,0,.2);
    width: 100%;
    min-width: 14em;
    display: none;

    &.visible {
      display: block;
    }

    .ui.input {
      margin: 0.8em;
      width: 94%;
    }

    .ui.divider {
      position: relative;
      margin: 0 0.8em;
      top: -0.85em;
      z-index: 4;
    }

    .ui.header {
      color: rgba(0,0,0, 0.75);
      margin: 0;
      padding: 1em 1.5em ;
      background: #f9f9f9;
      font-size: 0.9em;
      border-top: 1px solid rgba(0,0,0,.05);
    }

    label {
      margin-left: -2.2em;
      display: inline-block;

      &:before {
        width: 0;
        height: 0;
        border: none;
      }

      &:after {
        content: '';
        width: 0;
        height: 0;
        font-size: 0;
        background-color: transparent;
      }
    }

    .grouped.fields {
      .field {
        border-top: 1px solid rgba(0,0,0,.05);
        white-space: normal;
        word-wrap: normal;
        position: relative;
        cursor: pointer;
        display: block;
        height: auto;
        line-height: 1.2em;
        color: @black;
        margin: 0;
        padding: 0;
        font-size: 1em;
        text-transform: none;
        font-weight: 400;
        box-shadow: none;

        &:hover {
          background: rgba(0,0,0, 0.15);
          color: rgba(0,0,0, 0.8);
        }

        &.selected {
          background: rgba(0,0,0,0.08);
          color: rgba(0,0,0,.8);
          font-weight: 700;
        }

        .ui.checkbox {
          display: block;
          padding: 0.65em 2.1em 1.1em 1em;
        }
      }
    }
  }
}
