.ui.coi-tasks .actions {
  padding: 0.5rem 0;
  text-align: center;
}

.text.coi-next-reviewer {
  font-size: 0.9rem;
  padding-top: 3px;
}

.reviewer-with-conflict {
  color: @lightTextColor;
  pointer-events: none;
}

.checkbox-group-item {
  display: block !important;
}

