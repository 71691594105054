.flex-row {
  display: flex !important;
  flex-direction: row;
}

.icon.small-icon {
  display: inherit !important;
  font-size: 0.7em !important;
  margin-right: 0 !important;
  align-self: flex-start;
}