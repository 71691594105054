@treeBorderColor: #DDDDDD;
@treeColumnWidth: 33.33%;
@treeLightGray: #F5F5F5;
@treeDisabledGray: #999999;
@treeSelectedRowColor: #E6F1F6;
@treeDarkTextColor: #333333;

.tree.outer {
  padding: 20px;
  width: 100%;
}

.tree.container.inner {
  border: 1px solid @treeBorderColor;
  height: 500px;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}

.tree.column {
  border-right: 1px solid @treeBorderColor;
  display: inline-block;
  height: 100%;
  min-width: 220px;
  vertical-align: top;
  width: @treeColumnWidth;

  &:last-child {
    border-right: 0;
  }

  .header {
    background: @treeLightGray;
    border-bottom: 1px solid @treeBorderColor;
    border-right: 0;
    height: 50px;
    margin: 0;
    padding: 1.1rem;
  }

  .apply-to-all-container {
    align-items: center;
    display: flex;
    height: ~"calc(100% - 50px)";
    justify-content: center;
    padding: 20px;
    text-align: center;
    word-wrap: break-word;
    white-space: normal;
  }
}

.ui.selection.tree.list {
  height: ~"calc(100% - 50px)";
  margin: 0;
  overflow-y: scroll;

  .item {
    border-bottom: 1px solid @treeBorderColor;
    border-top: 0;
    color: @treeDarkTextColor;
    min-height: 50px;
    padding: 1.1rem;

    .text {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;
      width: 60%;
    }

    .ui.basic.button {
      color: @treeDarkTextColor !important;
      margin: 0;
      padding-top: 3px !important;
      width: 20%;

      &:hover {
        background: none !important;
      }
    }

    &.active {
      background: @treeLightGray;

      &.last-selected {
        background: @treeSelectedRowColor;
      }
    }

    &.disabled {
      .ui.basic.button {
        color: @treeDisabledGray !important;
      }
    }
  }
}
