/*******************************
            Theme
*******************************/

@type    : 'collection';
@element : 'instantnotifications';

@import (multiple) '@{themeConfigFile}';

.ui.instant.notifications {
  position: fixed;
  left: 50%;
  right: 50%;
  margin-right: -137.5px;
  top: 10px;
  z-index: 801;
}

.ui.instant.notifications .notification {
  background: rgba(0, 0, 0, 0.85);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  display: flex !important;
  margin-left: ~"calc(100% - 275px)";
  padding: 1em;
}

.ui.instant.notifications .notification .content {
  color: @white;
  font-size: @fontSize;
  flex: 1 0 0;
}

.ui.instant.notifications .notification .actions .vertical.buttons {
  margin: 0em !important;
  width: 100%;
}
.ui.instant.notifications .notification .actions .vertical.buttons .button {
  margin: 0em;
  margin-bottom: 0.5em;
}
.ui.instant.notifications .notification .actions .vertical.buttons .button:focus {
  box-shadow: 0px 0px 0px 2px #ffffff inset !important;
}
.ui.instant.notifications .notification .actions .vertical.buttons .button:last-child {
  margin-bottom: 0em;
}

.loadUIOverrides();
