& {
  .redactor-box.disabled {
    pointer-events: none;
  }
  .redactor-box.disabled,
  .redactor-box.disabled .redactor-editor
  {
    background: @pageBackground;
  }
  .redactor-box.disabled .redactor-toolbar {
    display: none;
  }
}

.redactor-box.fitted .redactor-editor {
  line-height: 1.33;
}

.redactor-toolbar {
  background-color: @tertiaryColor;
  border: 1px solid @grey;
  border-bottom: 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.redactor-editor {
  border: 1px solid @grey;
}
.redactor-toolbar li a.dropact {
  background-color: #3d79f2;
  color: @white;
}
.redactor-dropdown a {
  color: @black;
}
.redactor-dropdown a span {
  border-radius: 0;
  padding: 0;
}
.redactor-dropdown li a .description {
  font-size: 0.8rem;
  line-height: normal;
  color: @darkGrey;
}

.redactor-dropdown li:hover a .description {
  color: @offWhite;
}
