/*******************************
            Theme
*******************************/

@type    : 'collection';
@element : 'visualsearch';

@import (multiple) '@{themeConfigFile}';

/*******************************
        Visual Search;
*******************************/

/* Prompt */
.ui.visual.search .prompt{
  border-radius: @promptBorderRadius;
  min-height: @promptMinHeight;
  padding: @promptVerticalPadding @promptHorizontalPadding 0px @promptHorizontalPadding;
  position: relative;
}

.ui.visual.search .prompt > i.icon:not(.clear) {
  position: absolute;
  top: @iconPositionTop;
  left: @iconSearchPositionLeft;
}

.ui.visual.search .prompt > .token.clear {
  cursor: pointer;
  position: absolute;
  top: @iconPositionTop;
  right: @iconRemovePositionRight;
}

.ui.visual.search:not(.icon) .prompt {
  padding: @promptVerticalPadding @promptHorizontalPadding 0px @promptVerticalPadding;
}
.ui.visual.search:not(.icon) i.search.icon {
  display: none;
}

/* Placeholder */
.ui.visual.search .placeholder {
  display: none;
  position: absolute;
  opacity: @placeholderOpacity;
  top: @placeholderPositionTop;
}

/* Token Box */
.ui.visual.search .token.box {
  display: inline;
}
.ui.visual.search .token.box .ui.label {
  cursor: pointer;
  font-size: @visualSearchFontSize;
  margin-bottom: @tokenVerticalMargin;
}

.ui.visual.search .token.box .text {
  display: inline-block;
}

.ui.visual.search .token.box .detail {
  cursor: text;
  padding-left: @tokenDetailPadding;
  outline: @tokenTextOutline;
  min-height: @tokenMinHeight;
  min-width: @tokenMinWidth;
}

/* Input */
.ui.visual.search .token.input {
  cursor: text;
  display: inline-block;
  font-size: @tokenInputFontSize;
  line-height: @tokenInputMinHeight;
  margin-bottom: @tokenVerticalMargin;
  min-height: @tokenInputMinHeight;
  min-width: @tokenInputMinWidth;
  outline: @tokenInputOutline;
  padding: 0px @tokenInputHorizontalPadding;
  vertical-align: bottom;
}

/* Results */
.ui.visual.search .results {
  display: block;
  max-height: @resultsMaxHeight;
  overflow-y: auto;
  max-width: 100%;
}
.ui.visual.search .results.empty {
  display: none;
}

.ui.visual.search .results .result.footer {
  background-color: @resultFooterBackground;
  position: relative;
  text-align: center;
}

.ui.visual.search .results .result.footer i.count {
  position: absolute;
  left: @resultFooterHorizontalPadding;
  top: @resultFooterVerticalPadding;
  font-size: @resultCountFontSize;
}

.ui.visual.search .results .result.footer span.description {
  color: @textColor;
  font-weight: bold;
}

/*******************************
             States
*******************************/

/*--------------
     Active
---------------*/

.ui.visual.search .token.box .ui.label.selected:not(.editing) {
  color: @activeTokenColor;
  background-color: @activeTokenBackground;
  outline: @activeTokenOutline;
}

.ui.visual.search .token.box .ui.label.selected.editing .detail {
  color: @activeTokenTextColor;
}

@media only screen and (min-width: @computerBreakpoint) {
  .ui.visual.search .results {
    width: 28em;
    max-width: 100%;
  }
}

.loadUIOverrides();
