.notes.main.container .column,
.notes.main.container .ui.grid {
  padding: 0;
  margin: 0;
}

.new.note.container {
  box-shadow: none;
}

.note.search.container,
.note.header.container {
  padding: 1em !important;
}

.note.search.container {
  background: @lightGrey;
  border-top-left-radius: @defaultBorderRadius;
}

.note.header.container {
  background: @tertiaryColor;
  border-top-right-radius: @defaultBorderRadius;

  .redactor-in {
    margin: 0;
    overflow:hidden;
    white-space: nowrap;
    padding-left: 1px;
  }
}

.notes.content.container {
  box-shadow: 0px 0px 0px 1px @lightGrey, 0 1px 2px 0 @transparentBlack;

  .ui.row {
    box-shadow: none;
  }
}

.note.body.container {
  background: #FFFBE6;
  padding: 0 !important;
  border-bottom-right-radius: @defaultBorderRadius;
}

.note.list.container {
  padding: 0 !important;

  .ui.table {
    tbody {
      display: block;
      height: 512px;
      overflow-y: scroll;

      tr {
        display: table;
        table-layout: fixed;
        width: 100%;
      }
    }

    tr.active td,
    td.active,
    tr:focus {
      background: @primaryColor !important;
      color: @white !important;

      .ui.header {
        color: @white;
      }
    }
  }

  .note.item {
    height: 72px;

    .title,
    .body,
    .metadata {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:not(.active) {
      .metadata {
        color: @lightTextColor;
      }
    }
  }
}

.note.body.container {
  height: 512px;
}

.note.detail.container {
  height: 451px;
  padding: 1.5em 1.5em 0em !important;

  .redactor-editor {
    background: #FFFBE6 !important;
    border: 0 !important;
    height: ~"calc(451px - 1.5em)" !important;
    padding: 0 !important;
  }
}

.note.message.bottom.container {
  color: #7F7D72;
  padding: 1.5em !important;
}
