@nprogressColor: @appColor;

/* Make clicks pass-through */
.shared-header.inprogress {
  border-top: 14px solid #0076b6 !important;
  padding-top: 6px;
}

.shared-header.inprogress .right.menu {
  float: right;
}

.shared-header.progress-done {
  border-top: 20px solid #0076b6 !important;
}
#nprogress {
  pointer-events: none;
}
#nprogress .nprogress-loading {
  background: #cce2ff;

  position: absolute;
  z-index: 1030;
  top: 0;
  left: 0;

  width: 100%;
  height: 6px;
}

#nprogress .bar {
  background: @nprogressColor;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 6px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px @nprogressColor, 0 0 5px @nprogressColor;
  opacity: 1;
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: @nprogressColor;
  border-left-color: @nprogressColor;
  border-radius: 50%;

  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
