/*******************************
         Theme Overrides
*******************************/


/*--------------------
    Success
----------------------*/
.ui.success.dropdown > i.icon.dropdown:before {
  font-family: Icons !important;
  content: '\f00c';
  color: @green;
}

.ui.disabled.input {
  cursor: default;
  pointer-events: none;
}


/*--------------------
      Type Ahead Find
---------------------*/

/* Results */
.ui.input.type-ahead-find {
  .results {
    display: block;
    max-height: @resultsMaxHeight;
    overflow-y: auto;
    max-width: 100%;
  }
  ul.results {
   padding-left: 0;
  }
  .results.empty {
    display: none;
  }

  .results .result.footer {
    background-color: @resultFooterBackground;
    position: relative;
    text-align: center;
  }

  .results .result.footer i.count {
    position: absolute;
    left: @resultFooterHorizontalPadding;
    top: @resultFooterVerticalPadding;
    font-size: @resultCountFontSize;
  }

  .results .result.footer span.description {
    color: @textColor;
    font-weight: bold;
  }
}

/*--------------------
      Currency
---------------------*/

.ui.input.currency {
  position: relative;
  width: 100%;
}

.ui.input.currency i.icon {
  top: 0;
  opacity: 1;
  color: @currencyIconColor;
  width: 1.75em;
  position: absolute;
}

.ui.input.currency input {
  padding-left: 1.5em !important;
}
