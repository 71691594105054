#app-content > .page {
  padding: @pageGutter;
}

.page.header {
  background: white;
  margin-left: -@pageGutter;
  margin-right: -@pageGutter;
  margin-top: -@pageGutter;
  padding: 1.5em @pageGutter;
  margin-bottom: 2em;
  box-shadow: 0px 0px 0px 1px rgba(39, 41, 43, 0.15), 0px 1px 2px 0 rgba(0, 0, 0, 0.15);
}

.page.header.fixed {
  border: none;
  margin-top: 0;
  position: fixed;
  z-index: 100;
  width: 100%;
}

.page.header .ui.header {
  margin-bottom: 0em;
}
