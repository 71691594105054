/*******************************
         Theme Overrides
*******************************/

/* Only one segment in segment group */
.ui.segments > .segment:only-child {
  border-radius: @borderRadius @borderRadius @borderRadius @borderRadius;
}

.ui:not(.inverted):not(.active):not(.tab).segment.selectable:hover {
  background: darken(@white, 3%);
}

.ui:not(.inverted):not(.tab):not(.basic).segment.active {
  color: @white;
  background: @primaryColor;
}


/* Padding */
.ui.segment.padded {
  padding: @paddedVerticalPadding @paddedHorizontalPadding;
}
.ui[class*="very padded"].segment {
  padding: @veryPaddedVerticalPadding @veryPaddedHorizontalPadding;
}
