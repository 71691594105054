#apps-dropdown.ui.dropdown .menu {
  // this is to allow the border-radius to be visible
  overflow: hidden;
}

/*
  Image special handling
 */
#apps-dropdown.ui.dropdown .menu > .item > .icon,
#apps-dropdown.ui.dropdown .menu > .item > .label,
#apps-dropdown.ui.dropdown .menu > .item > .flag,
#apps-dropdown.ui.dropdown .menu > .item > .image,
#apps-dropdown.ui.dropdown .menu > .item > img,
#apps-dropdown.ui.dropdown > .text > .icon,
#apps-dropdown.ui.dropdown > .text > .label,
#apps-dropdown.ui.dropdown > .text > .flag,
#apps-dropdown.ui.dropdown > .text > img,
#apps-dropdown.ui.dropdown > .text > .image {
  height: 1.5em;
  margin-top: -0.7em;
  margin-bottom: -0.5em;
}

#apps-dropdown.ui.dropdown .menu .active.item {
  opacity: 0.5;
  background: transparent;
}
