.attachment.point {
  border: 2px dashed @grey;
  padding: 2em;
  text-align: center;

  .descriptive.text {
    display: block;
    font-weight: bold;
    margin-bottom: 2px;
  }

  .descriptive.sub.text {
    display: block;
    font-weight: normal;
    margin-bottom: 0.5em;
  }

  &.disabled {
    opacity: 0.5;
  }
}
