//custom styling for modal so X for closing window appears within the Modal2
.ui.modal.confirmation {
  > .close {
    top: 0rem;
    right: 0rem;
  }

  .ui.input {
    margin: 1em 0em;
  }

  //if a textarea is more than 8 lines, this will trigger autosize adding in scrollbars
  div.ui.input > textarea {
    max-height: unit((@lineHeight * @fontSize * 8.05), px);
  }

  tr.selected,
  tr.selected::selection {
    background-color: @highlightBackground;
  }

  .progressive {
    margin: 1em 0 0.5em 2.4em;

    .ui.input {
      margin-top: 0.2em;
    }
  }
}
