/*******************************
         Theme Overrides
*******************************/
.ui.yellow.label {
  color: @labelTextColor !important;
}

.ui.label.wrappable {
  max-width: 100%;
  overflow: hidden;
}
.ui.label.wrappable > * {
  display: table-cell !important;
}
.ui.label.wrappable > i.icon {
  min-width: (@iconDistance + 1em);
  vertical-align: middle;
  text-align: left;
}
.ui.label.wrappable > .text {
  white-space: normal;
  text-align: left;
}
