.vertically.spaced.components.container {
  & > * {
    margin-top: 0;
  }
  & > *:not(.last-child) {
    margin-bottom: 2em;
  }
}
.vertically.spaced.components.container.compact {
  & > * {
    margin-top: 0;
  }
  & > *:not(.last-child) {
    margin-bottom: 1em;
  }
}
[class*="very compact"].vertically.spaced.components.container.compact {
  & > * {
    margin-top: 0;
  }
  & > *:not(.last-child) {
    margin-bottom: 0.5em;
  }
}
