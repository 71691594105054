.required-muted {
  color: red;
  opacity: 0.5;
  font-size: 80%;
}

// mobile screen
@media only screen and (max-width: @largestMobileScreen) {
  .ui.grid [class*='right aligned'].desktop.only.column {
    text-align: left !important;
  }
}

[class*='right desktop aligned'] {
  text-align: right !important;
  @media only screen and (max-width: @largestTabletScreen) {
    text-align: left !important;
  }
}
