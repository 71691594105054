// fix for a redactor z-index
.redactor-editor,
.redactor-box,
.redactor-box textarea {
  z-index: 0 !important;
}
.redactor-box.read-only {
  pointer-events: none;
}
.redactor-box.disabled {
  pointer-events: none;
  background: #F5F5F5;
}
.redactor-box.disabled .redactor-editor {
  background: #F5F5F5;
}
.redactor-box.basic,
.redactor-box.basic .redactor-editor {
  background: transparent !important;
}
.redactor-box.disabled .redactor-toolbar {
  display: none;
}
.redactor-box.borderless .redactor-editor {
  border:none;
}
.redactor-box.fitted .redactor-editor {
  padding: 0;
  min-height: 0;
}
.redactor-box.fitted .redactor-editor p,
.redactor-box.fitted .redactor-editor ul,
.redactor-box.fitted .redactor-editor ol,
.redactor-box.fitted .redactor-editor dl,
.redactor-box.fitted .redactor-editor blockquote,
.redactor-box.fitted .redactor-editor hr,
.redactor-box.fitted .redactor-editor pre,
.redactor-box.fitted .redactor-editor table,
.redactor-box.fitted .redactor-editor figure,
.redactor-box.fitted .redactor-editor address {
  margin-bottom: 0;
}

.text-left {text-align: left;}
.text-center {text-align: center;}
.text-right {text-align: right;}
.text-justify {text-align: justify;}

