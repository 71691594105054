/*******************************
         Theme Overrides
*******************************/

/*-------------------
      Bulleted
--------------------*/

ul.ui.list li.completed:before,
.ui.bulleted.list .list > .item.completed:before,
.ui.bulleted.list > .item.completed:before {
  content: @checkMarkCharacter;
  color: @checkMarkColor;
  font-weight: bold;
}

.ui.padded.list:not(.horizontal) > .item {
  padding-left: @paddedHorizontalPadding;
  padding-right: @paddedHorizontalPadding;
}

.ui.list .item a.secondary {
  color: @white !important;
}
