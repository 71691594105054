
#documentsTabTable {

    border: none;
    padding-top: 0;
    margin-bottom: 1em;

  TH, TR, TD {
    border: none;
  }

}

.disclosure-download {

  span {
    color: @primaryColor;
    text-decoration: underline;
  }
}
